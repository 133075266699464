<template>
  <div class="cpt-box_popup" :style="containerStyle">
    <div class="popup-inner-wrap">
      <div class="popup_inner">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    containerStyle: {
      type: Object | String,
      default: () => ''
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-box_popup {
  margin-left: 500px;
  margin-bottom: 50px;
  position: fixed;
  bottom: 0px;
  right: 100px;
  min-height: 400px;
  z-index: 9;
  // background: url("~@/assets/images/home/plate_left_bg.png") no-repeat;
  // background: rgba(12, 34, 73, 0.7);
  //   border-radius: 10px;
  //   border: 1px solid rgba(26, 92, 246, 0.5);
  //   backdrop-filter: blur(1px);

  background-size: 100% 100%;
  padding: 2rem 1.6rem 1rem 2rem;
  min-width: 482px;
  box-sizing: border-box;
  // .popup-inner-wrap {
  //   width: 100%;
  //   height: 100%;
  //   box-sizing: border-box;
  //   .popup_inner {
  //     width: 100%;
  //     height: 100%;
  //     box-sizing: border-box;
  //   }
  // }
}
</style>
